import { createTheme } from '@material-ui/core';

const defaultBtnMaterialTheme = createTheme({
	palette: {
		// primary: red, //aae6bf
		primary: {
			main: '#aae6bf',
		},
		secondary: {
			main: '#aae6bf',
			// main: "#fff"
		},
		// grey: { main: "#22BF19" }
	},
	overrides: {
		MuiButton: {
			root: {
				borderWidth: 50,
				borderRadius: 20,
				// backgroundColor: "#fff",
				// backgroundColor: "#999"
				// padding: "10px 10px"
			},
			label: {
				fontWeight: 700,
				fontSize: '18px',
				fontColor: 'black',
				padding: '5px 2px 5px 2px',
				// fontHeight: "1px",
				margin: '-10px -2px -10px -2px',
			},
		},
		MuiFormLabel: {
			root: {
				// "&$focused": {
				color: '#999',
				fontSize: '22px',
				backgroundColor: '#fff',
				backgroundColor: '#fff',
				margin: '-2px 0 0 -5px',
				padding: '0 10px 0 10px',
				// }
			},
		},
	},
});

export default defaultBtnMaterialTheme;
