import React, { Suspense, lazy, useEffect, useState } from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { gql } from '@apollo/client';
import { createBrowserHistory } from 'history';

import 'assets/scss/material-kit-pro-react.scss?v=1.8.0';
import { withAuthentication } from 'utils/Session';
import { useNotaries } from 'utils/Notaries';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { onError } from '@apollo/client/link/error';

import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { GET_BOOKING_UPDATE_USER } from 'utils/Graphql/queries';
import { SET_BOOKING_AS_VIEWED } from 'utils/Graphql/mutations';
import { useSnackbar } from 'notistack';
import { Button, ThemeProvider, makeStyles } from '@material-ui/core';
import defaultBtnMaterialTheme from 'utils/Themes/defaultMaterialTheme';
import NotificationTheme from 'utils/Themes/notificationTheme';
import MuiButton from '@material-ui/core/Button';
import clsx from 'clsx';
import Cookies from 'js-cookie';

import useBookingNotification from './Notification';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.10.0';

console.log('............MAIN I N D E X..................');
console.log(process.env.NODE_ENV);
console.log('process.env.REACT_APP_endpoint');
console.log(process.env.REACT_APP_endpoint);

const token = localStorage.getItem('token');
console.log(`token`);
console.log(token);
console.log(`REACT_APP_PRISMA_SECRET`);
console.log(process.env.REACT_APP_PRISMA_SECRET);

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_endpoint
      : process.env.REACT_APP_prodEndpoint,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  console.log(`token`);
  console.log(token);
  return {
    headers: {
      ...headers,
      userAuthorization: token ? `Bearer ${token}` : '',
      authorization: `Bearer ${process.env.REACT_APP_PRISMA_SECRET}`,
    },
  };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   wsLink,
//   httpLink
// );

// const link = from([
//   httpLink,
//   authLink,
//   errorLink
// ]);
// const cache = new InMemoryCache();

const SignUpWeb = lazy(() => import('../SignUpWeb'));
const UserName = lazy(() => import('screens/Account/UserName'));
const Email = lazy(() => import('screens/Account/Email'));
const PhoneNumber = lazy(() => import('screens/Account/PhoneNumber'));
const Password = lazy(() => import('screens/Account/Password'));
const PasswordForget = lazy(() => import('screens/PasswordForget'));
// const PasswordForgot = lazy(() => import('screens/PasswordForget/PasswordForgot'))
const RegSuccessDialog = lazy(() => import('../SignUpWeb/success'));
const Privacy = lazy(() => import('screens/About/Privacy'));

const Data = lazy(() => import('screens/Account/Data'));
const Download = lazy(() => import('screens/Account/Download'));
const Delete = lazy(() => import('screens/Account/Delete'));
const Billing = lazy(() => import('screens/Account/Billing'));
const SignOutFirebase = lazy(() => import('screens/SignOutFirebase'));
// const Account = lazy(() => import("screens/Account"))
const BookingsLink = lazy(() => import('screens/BookingsLink'));
const BookingsMember = lazy(() => import('screens/BookingsMember'));
const BookingsMemberLink = lazy(() => import('screens/BookingsMemberLink'));
const ContactLink = lazy(() => import('screens/ContactLink'));
const UpgradeAccount = lazy(() => import('screens/Account/UpgradeAccount'));
const TermsOfService = lazy(() => import('screens/About/TermsOfService'));

// const SearchNotary = lazy(() => import('screens/Search'))
const SearchNotaryZ = lazy(() => import('screens/Search'));
const SearchNotaryZUser = lazy(() => import('screens/Search/SearchZUser'));
const SearchSignOutFirebase = lazy(() =>
  import('screens/Search/SignoutFromSearch')
);
const MapContainerBook = lazy(() => import('screens/Search/MapZBook'));

const MapZBookPricingLink = lazy(() =>
  import('screens/Search/MapZBookPricingLink')
);

const GCashSuccess = lazy(() => import('screens/Search/GCashSuccess'));
const GCashFail = lazy(() => import('screens/Search/GCashFail'));

// const PasswordForgot = lazy(() => import('screens/PasswordForget/PasswordForgot'))
// const About = lazy(() => import("screens/About/AboutForUsers"))
// const FrequentlyAsked = lazy(() => import("screens/About/FrequentlyAskedForUsers"))
// const Learn = lazy(() => import("screens/About/Learn"))
const Reviews = lazy(() => import('screens/Reviews'));
// const TermsOfService = lazy(() => import("screens/About/TermsOfService"))
// const Privacy = lazy(() => import("screens/About/Privacy"))

// const EsqPay = lazy(() => import("../../EsqPay"))
// const EsqPaySignup = lazy(() => import('../../EsqPaySignUp'))
// const EsqPayUser = lazy(() => import('../../EsqPayUser'))

const AppDrawerLayout = lazy(() => import('../../layouts/AppDrawer.js'));
const UserDrawerLayout = lazy(() => import('../../layouts/UserDrawer.js'));
const SignInDrawerLayout = lazy(() => import('../../layouts/SignInDrawer.js'));

console.log('Main ', JSON.parse(localStorage.getItem('authUser')));

// var hist = createBrowserHistory();
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    backgroundColor: 'transparent',
  },
  label: {
    fontWeight: 600,
    fontSize: '14px',
    margin: '0px 0px 0px 0px',
  },
  contained: {
    backgroundColor: '#aae6bf',
  },
}));

const Main = (props) => {
  // console.log(props)
  const classes = useStyles();
  let location = useLocation();
  let history = useHistory();
  const { client, authUser } = props;
  const {
    authUserContext,
    setAuthUserContext,
    setOriginalLocation,
  } = useNotaries();
  const [bookingUpdates, setBookingUpdates] = useState([]);
  const bookingNotification = useBookingNotification({
    bookingUpdates,
    setBookingUpdates,
    classes,
  });

  useEffect(() => {
    // console.log('Main useEffect', auth)
    // console.log(props)
    let auth = JSON.parse(localStorage.getItem('authUser'));
    if (auth !== null) {
      const { email } = auth;

      console.log('Main email', email);
      console.log('Main authUserContext', authUserContext);
      if (typeof authUserContext === 'undefined' || authUserContext === null) {
        // const client__ = new ApolloClient({
        //   link,
        //   cache,
        //   // Provide some optional constructor fields
        //   name: "react-web-client",
        //   version: "1.3",
        //   queryDeduplication: false,
        //   defaultOptions: {
        //     watchQuery: {
        //       fetchPolicy: "cache-and-network",
        //     },
        //   },
        //   shouldBatch: true,
        // });
        const client__ = new ApolloClient({
          link: authLink.concat(errorLink.concat(httpLink)),
          // link,
          cache: new InMemoryCache(),
        });
        client__
          .query(
            {
              query: LOG_IN,
              variables: {
                email,
              },
            },
            {
              options: { fetchPolicy: 'network-only' },
            }
          )
          .then((response) => {
            const { data } = response;
            console.log('Main data', data);
            if (data) {
              if (data.logIn !== null) {
                const { id, email, role } = data.logIn;
                setAuthUserContext({ user: data.logIn });
                // if (data.logIn.role === 'MEMBER') {
                //   history.push('/app/dashboard')
                // } else {
                //   history.push('/usr/dashboard')
                // }
              }
            }
          })
          .catch((error) => { });
      }
    }
  }, []);

  return (
    // <Router history={hist} component={App}>
    // <Router history={hist} >
    <>
      {JSON.parse(localStorage.getItem('authUser')) === null ? (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route
                exact
                path='/auth/privacyPolicy'
                render={(props) => <Privacy client={client} {...props} />}
              />
              <Route
                exact
                path='/auth/termsOfService'
                render={(props) => <TermsOfService client={client} {...props} />}
              />
              {/* <Route
								exact
								path='/auth/signupweb'
								render={(props) => <SignUpWeb client={client} {...props} />}
							/> */}
              {/* <Route
                exact
                path="/auth/contact"
                render={(props) => <Contact client={client} {...props} />}
              /> */}
              <Route
                exact
                path='/auth/passwordforget'
                render={(props) => <PasswordForget client={client} {...props} />}
              />
              <Route
                exact
                path='/app/bookingsmemberlink'
                render={(props) => <BookingsMemberLink client={client} {...props} />}
              />
              {/* <Route
              exact
              path="/auth/passwordforgot"
              render={(props) => <PasswordForgot client={client} {...props} />}
            /> */}
              {/* <Route
                exact
                path="/auth/search"
                render={(props) => <SearchNotary client={client} {...props} />}
              /> */}
              <Route
                exact
                path='/auth/regsuccess'
                render={(props) => <RegSuccessDialog client={client} {...props} />}
              />
              <Route
                exact
                path='/auth/searchdistance'
                render={(props) => <SearchNotaryZ client={client} {...props} />}
              />
              <Route
                exact
                path='/auth/pricinglink'
                render={(props) => <MapZBookPricingLink client={client} {...props} />}
              />
              {/* <Route
                exact
                path="/usr/contactlink"
                render={(props) => <ContactLink client={client} {...props} />}
              /> */}
              <Route
                exact
                path='/app/contactlink'
                render={(props) => <ContactLink client={client} {...props} />}
              />

              {/* <Route
            exact
            path="/auth/nnp"
            render={(props) => <EsqPaySignup client={client} {...props} />}
          /> */}
              <Route
                path='/auth'
                render={(props) => <SignInDrawerLayout client={client} {...props} />}
              />
              <Redirect from='/' to='/auth/dashboard' />
            </Switch>
          </Suspense>
        </>
      ) : authUserContext &&
        authUserContext.user &&
        authUserContext.user.role === 'MEMBER' ? (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route
                exact
                path='/app/username'
                render={(props) => <UserName client={client} {...props} />}
              />
              <Route
                exact
                path='/app/email'
                render={(props) => <Email client={client} {...props} />}
              />
              <Route
                exact
                path='/app/phonenumber'
                render={(props) => <PhoneNumber client={client} {...props} />}
              />

              <Route
                exact
                path='/app/password'
                render={(props) => <Password client={client} {...props} />}
              />
              <Route
                exact
                path='/app/data'
                render={(props) => <Data client={client} {...props} />}
              />
              <Route
                exact
                path='/app/download'
                render={(props) => <Download client={client} {...props} />}
              />
              <Route
                exact
                path='/app/delete'
                render={(props) => <Delete client={client} {...props} />}
              />
              <Route
                exact
                path='/app/billing'
                render={(props) => <Billing client={client} {...props} />}
              />
              <Route
                exact
                path='/app/searchsignout'
                render={(props) => <SearchSignOutFirebase client={client} {...props} />}
              />
              <Route
                exact
                path='/app/map'
                render={(props) => <MapContainerBook client={client} {...props} />}
              />
              {/* <Route
								exact
								path='/app/signout'
								render={(props) => <SignOutFirebase client={client} {...props} />}
							/> */}
              {/* <Route
                exact
                path="/app/account"
                render={(props) => <Account client={client} {...props} />}
              /> */}
              <Route
                exact
                path='/app/bookingsmemberlink'
                render={(props) => <BookingsMemberLink client={client} {...props} />}
              />
              <Route
                exact
                path='/app/upgradeaccount'
                render={(props) => <UpgradeAccount client={client} {...props} />}
              />

              <Route
                exact
                path='/app/regsuccess'
                render={(props) => <RegSuccessDialog client={client} {...props} />}
              />
              <Route
                exact
                path='/usr/gcashsuccess'
                render={(props) => <GCashSuccess client={client} {...props} />}
              />
              <Route
                exact
                path='/usr/gcashfail'
                render={(props) => <GCashFail client={client} {...props} />}
              />
              <Route
                exact
                path='/auth/signupweb'
                render={(props) => <SignUpWeb client={client} {...props} />}
              />
              <Route
                exact
                path='/app/contactlink'
                render={(props) => <ContactLink client={client} {...props} />}
              />
              {/* <Route
                exact
                path="/user/dashboard"
                render={(props) => <EsqPayUser client={client} {...props} />}
              /> */}
              <Route
                path='/app'
                render={(props) => <AppDrawerLayout client={client} {...props} />}
              />
              <Redirect from='/' to='/app/dashboard' />
            </Switch>
          </Suspense>
        </>
      ) : authUserContext &&
        authUserContext.user &&
        authUserContext.user.role === 'USER' ? (
        <></>
      ) : (
        <></>
      )}
      {/* </Router> */}
    </>
  );
};

export const LOG_IN = gql`
	query logIn($email: String!) {
		logIn(email: $email) {
			id
			email
			displayName
			firstName
			lastName
			middleName
			hasConfirmedAgreement
			hasRespondedToInvite
			hasInitiallyLoggedIn
			isLoggedIn
			role
		}
	}
`;

export default withAuthentication(Main);
