import { gql } from '@apollo/client';

export const UPDATE_MEMBER_USERNAME = gql`
	mutation UpdateMemberx($memberId: ID!, $userName: String!) {
		updateMemberx(memberId: $memberId, userName: $userName) {
			id
			userName
		}
	}
`;

export const UPDATE_MEMBER_EMAIL = gql`
	mutation UpdateMemberx($memberId: ID!, $email: String!) {
		updateMemberx(memberId: $memberId, email: $email) {
			id
			email
		}
	}
`;

export const UPDATE_MEMBER_PHONENUMBER = gql`
	mutation UpdateMemberx($memberId: ID!, $phoneNo: String!) {
		updateMemberx(
			memberId: $memberId # phoneNo: $phoneNo
		) {
			id
			# phoneNo
		}
	}
`;

export const UPDATE_MEMBER_MUTATION = gql`
	mutation UpdateMemberx(
		$memberId: ID!
		$email: String
		$displayName: String
		$firstName: String
		$lastName: String
		$isLoggedIn: Boolean
		$logInDate: DateTime
		$rollNumber: String
		$streetNumber: String
		$barangay: String
		$cityMunicipality: String
		$subCityMunicipality: String
		$province: String
		$city: String
		$zipCode: String
		$lat: Float
		$lng: Float
		$serialNumber: String
		$cityProvince: String
		$dateGranted: DateTime
		$expiryDate: DateTime
		$mobileNumber: String
		$landLine: String
		$facebookLink: String
		$twitterLink: String
		$instagramLink: String
		$monday: Boolean
		$tuesday: Boolean
		$wednesday: Boolean
		$thursday: Boolean
		$friday: Boolean
		$saturday: Boolean
		$sunday: Boolean
		$mondayFrom: String
		$mondayTo: String
		$tuesdayFrom: String
		$tuesdayTo: String
		$wednesdayFrom: String
		$wednesdayTo: String
		$thursdayFrom: String
		$thursdayTo: String
		$fridayFrom: String
		$fridayTo: String
		$saturdayFrom: String
		$saturdayTo: String
		$sundayFrom: String
		$sundayTo: String
		$mondayApp: Boolean
		$tuesdayApp: Boolean
		$wednesdayApp: Boolean
		$thursdayApp: Boolean
		$fridayApp: Boolean
		$saturdayApp: Boolean
		$sundayApp: Boolean
		$status: String
		$bankName: String
    $bankAccountName: String
		$bankAccount: String
	) {
		updateMemberx(
			memberId: $memberId
			email: $email
			displayName: $displayName
			firstName: $firstName
			lastName: $lastName
			isLoggedIn: $isLoggedIn
			logInDate: $logInDate
			rollNumber: $rollNumber
			streetNumber: $streetNumber
			barangay: $barangay
			cityMunicipality: $cityMunicipality
			subCityMunicipality: $subCityMunicipality
			province: $province
			city: $city
			zipCode: $zipCode
			lat: $lat
			lng: $lng
			serialNumber: $serialNumber
			cityProvince: $cityProvince
			dateGranted: $dateGranted
			expiryDate: $expiryDate
			mobileNumber: $mobileNumber
			landLine: $landLine
			facebookLink: $facebookLink
			twitterLink: $twitterLink
			instagramLink: $instagramLink
			monday: $monday
			tuesday: $tuesday
			wednesday: $wednesday
			thursday: $thursday
			friday: $friday
			saturday: $saturday
			sunday: $sunday
			mondayFrom: $mondayFrom
			mondayTo: $mondayTo
			tuesdayFrom: $tuesdayFrom
			tuesdayTo: $tuesdayTo
			wednesdayFrom: $wednesdayFrom
			wednesdayTo: $wednesdayTo
			thursdayFrom: $thursdayFrom
			thursdayTo: $thursdayTo
			fridayFrom: $fridayFrom
			fridayTo: $fridayTo
			saturdayFrom: $saturdayFrom
			saturdayTo: $saturdayTo
			sundayFrom: $sundayFrom
			sundayTo: $sundayTo
			mondayApp: $mondayApp
			tuesdayApp: $tuesdayApp
			wednesdayApp: $wednesdayApp
			thursdayApp: $thursdayApp
			fridayApp: $fridayApp
			saturdayApp: $saturdayApp
			sundayApp: $sundayApp
			status: $status
			bankName: $bankName
      bankAccountName: $bankAccountName
			bankAccount: $bankAccount
		) {
			email
		}
	}
`;

export const UPDATE_MEMBER_MUTATION_SIGNUP = gql`
	mutation UpdateMemberx(
		$memberId: ID!
		$hasRespondedToInvite: Boolean
		$hasConfirmedAgreement: Boolean
		$hasInitiallyLoggedIn: Boolean
	) {
		updateMemberx(
			memberId: $memberId
			hasRespondedToInvite: $hasRespondedToInvite
			hasConfirmedAgreement: $hasConfirmedAgreement
			hasInitiallyLoggedIn: $hasInitiallyLoggedIn
		) {
			id
		}
	}
`;

export const REGISTRATION_COMPLETE_MUTATION = gql`
	mutation REGISTRATION_COMPLETE_MUTATION(
		$email: String!
		$displayName: String # $phoneNo: String
	) {
		registrationComplete(
			email: $email
			displayName: $displayName # phoneNo: $phoneNo
		) {
			message
		}
	}
`;

export const CREATE_CLIENT_MUTATION = gql`
	mutation CreateClient(
		$memberId: ID!
		$lastName: String
		$firstName: String
		$company: String
		$hourlyRate: Float
		$parentId: ID
		$status: String
		$ord: Int
	) {
		createClient(
			memberId: $memberId
			lastName: $lastName
			firstName: $firstName
			company: $company
			hourlyRate: $hourlyRate
			parentId: $parentId
			status: $status
			ord: $ord
		) {
			id
			lastName
			firstName
			company
			hourlyRate
			parentId
			status
			ord
		}
	}
`;

export const CREATE_CASE_MUTATION = gql`
	mutation CreateCase(
		$memberId: ID!
		$caseCode: String
		$caseTitle: String
		$billingType: String
		$fixedRate: Float
		$clientId: ID!
		$parentId: ID
		$status: String
		$ord: Int
	) {
		createCase(
			memberId: $memberId
			caseCode: $caseCode
			caseTitle: $caseTitle
			billingType: $billingType
			fixedRate: $fixedRate
			clientId: $clientId
			parentId: $parentId
			status: $status
			ord: $ord
		) {
			id
			caseCode
			caseTitle
			billingType
			fixedRate
			parentId
			status
			ord
		}
	}
`;

export const CREATE_MEMBER_MUTATION = gql`
	mutation CreateMember($email: String) {
		createMember(email: $email) {
			id
		}
	}
`;

export const CREATE_MEMBER_MUTATION2 = gql`
	mutation CreateMember2(
		$email: String!
		$lastName: String
		$firstName: String
		$rollNumber: String
		$streetNumber: String
		$barangay: String
		$cityMunicipality: String
		$subCityMunicipality: String
		$province: String
		$city: String
		$zipCode: String
		$lat: Float
		$lng: Float
		$password: String
		$serialNumber: String
		$cityProvince: String
		$dateGranted: DateTime
		$expiryDate: DateTime
		$mobileNumber: String
		$landLine: String
		$facebookLink: String
		$twitterLink: String
		$instagramLink: String
		$authProvider: String
		$monday: Boolean
		$tuesday: Boolean
		$wednesday: Boolean
		$thursday: Boolean
		$friday: Boolean
		$saturday: Boolean
		$sunday: Boolean
		$mondayFrom: String
		$mondayTo: String
		$tuesdayFrom: String
		$tuesdayTo: String
		$wednesdayFrom: String
		$wednesdayTo: String
		$thursdayFrom: String
		$thursdayTo: String
		$fridayFrom: String
		$fridayTo: String
		$saturdayFrom: String
		$saturdayTo: String
		$sundayFrom: String
		$sundayTo: String
		$mondayApp: Boolean
		$tuesdayApp: Boolean
		$wednesdayApp: Boolean
		$thursdayApp: Boolean
		$fridayApp: Boolean
		$saturdayApp: Boolean
		$sundayApp: Boolean
		$occEmail: String
		$bankName: String
    $bankAccountName: String
		$bankAccount: String
	) {
		createMember2(
			email: $email
			lastName: $lastName
			firstName: $firstName
			rollNumber: $rollNumber
			streetNumber: $streetNumber
			barangay: $barangay
			cityMunicipality: $cityMunicipality
			subCityMunicipality: $subCityMunicipality
			province: $province
			city: $city
			zipCode: $zipCode
			lat: $lat
			lng: $lng
			password: $password
			serialNumber: $serialNumber
			cityProvince: $cityProvince
			dateGranted: $dateGranted
			expiryDate: $expiryDate
			mobileNumber: $mobileNumber
			landLine: $landLine
			facebookLink: $facebookLink
			twitterLink: $twitterLink
			instagramLink: $instagramLink
			authProvider: $authProvider
			monday: $monday
			tuesday: $tuesday
			wednesday: $wednesday
			thursday: $thursday
			friday: $friday
			saturday: $saturday
			sunday: $sunday
			mondayFrom: $mondayFrom
			mondayTo: $mondayTo
			tuesdayFrom: $tuesdayFrom
			tuesdayTo: $tuesdayTo
			wednesdayFrom: $wednesdayFrom
			wednesdayTo: $wednesdayTo
			thursdayFrom: $thursdayFrom
			thursdayTo: $thursdayTo
			fridayFrom: $fridayFrom
			fridayTo: $fridayTo
			saturdayFrom: $saturdayFrom
			saturdayTo: $saturdayTo
			sundayFrom: $sundayFrom
			sundayTo: $sundayTo
			mondayApp: $mondayApp
			tuesdayApp: $tuesdayApp
			wednesdayApp: $wednesdayApp
			thursdayApp: $thursdayApp
			fridayApp: $fridayApp
			saturdayApp: $saturdayApp
			sundayApp: $sundayApp
			occEmail: $occEmail
			bankName: $bankName
      bankAccountName: $bankAccountName
			bankAccount: $bankAccount
		) {
			id
			email
			firstName
			lastName
			mobileNumber
			role
		}
	}
`;

export const INVITE_MEMBER_MUTATION = gql`
	mutation INVITE_MEMBER_MUTATION($email: String!, $displayName: String) {
		inviteMember(email: $email, displayName: $displayName) {
			message
		}
	}
`;

export const CREATE_MEMBER_MUTATION1 = gql`
	mutation CreateMemberx(
		$email: String!
		$displayName: String
		$photoURL: String
		$hasRespondedToInvite: Boolean
		$hasConfirmedAgreement: Boolean
		$hasInitiallyLoggedIn: Boolean
	) {
		createMemberx(
			email: $email
			displayName: $displayName
			photoURL: $photoURL
			hasRespondedToInvite: $hasRespondedToInvite
			hasConfirmedAgreement: $hasConfirmedAgreement
			hasInitiallyLoggedIn: $hasInitiallyLoggedIn
		) {
			id
		}
	}
`;
export const UPDATE_CLIENT_MUTATION = gql`
	mutation UpdateClient(
		$memberId: ID!
		$clientId: ID!
		$lastName: String
		$firstName: String
		$company: String
		$hourlyRate: Float
		$actionDate: DateTime
		$action: String
		$parentId: ID
		$status: String
		$ord: Int
	) {
		updateClient(
			memberId: $memberId
			clientId: $clientId
			lastName: $lastName
			firstName: $firstName
			company: $company
			hourlyRate: $hourlyRate
			actionDate: $actionDate
			action: $action
			parentId: $parentId
			status: $status
			ord: $ord
		) {
			id
			firstName
			lastName
			company
			parentId
			status
			ord
			actionDate
		}
	}
`;

export const UPDATE_CASE_MUTATION = gql`
	mutation updateCase(
		$memberId: ID!
		$clientId: ID!
		$caseId: ID!
		$caseCode: String
		$caseTitle: String
		$parentId: ID
		$status: String
		$ord: Int
		$actionDate: DateTime
	) {
		updateCase(
			memberId: $memberId
			clientId: $clientId
			caseId: $caseId
			caseCode: $caseCode
			caseTitle: $caseTitle
			parentId: $parentId
			status: $status
			ord: $ord
			actionDate: $actionDate
		) {
			id
			caseCode
			caseTitle
			parentId
			status
			ord
			actionDate
		}
	}
`;

export const DELETE_CLIENT_MUTATION = gql`
	mutation DeleteClient($clientId: ID!) {
		deleteClient(clientId: $clientId) {
			id
			firstName
			lastName
			company
			parentId
			status
			ord
			actionDate
		}
	}
`;
export const DELETE_CASE_MUTATION = gql`
	mutation DeleteCase($caseId: ID!) {
		deleteCase(caseId: $caseId) {
			id
			caseCode
			caseTitle
			parentId
			status
			ord
			actionDate
		}
	}
`;

export const CREATE_DOWNLOAD_REQUEST = gql`
	mutation CreateDownloadRequest(
		$memberId: ID!
		$date: DateTime!
		$notes: String
	) {
		createDownloadRequest(memberId: $memberId, date: $date, notes: $notes) {
			id
			member {
				id
				email
			}
			date
			dateServed
			dateCancelled
			requestType
			notes
			status
		}
	}
`;

export const CREATE_DELETE_ACCOUNT_REQUEST = gql`
	mutation CreateDeleteRequest(
		$memberId: ID!
		$date: DateTime!
		$notes: String
		$dateDeletion: DateTime
	) {
		createDeleteRequest(
			memberId: $memberId
			date: $date
			notes: $notes
			dateDeletion: $dateDeletion
		) {
			id
			member {
				id
				email
			}
			date
			dateServed
			dateCancelled
			dateDeletion
			requestType
			notes
			status
		}
	}
`;

export const CANCEL_SERVICE_REQUEST = gql`
	mutation CancelServiceRequest(
		$serviceId: ID!
		$dateCancelled: DateTime! # $memberId: ID!
	) {
		cancelServiceRequest(
			serviceId: $serviceId
			dateCancelled: $dateCancelled # memberId: $memberId
		) {
			id
			date
			dateServed
			dateCancelled
			requestType
			notes
			status
		}
	}
`;

export const LOG_OUT = gql`
	mutation logOut($email: String!) {
		logOut(email: $email) {
			id
		}
	}
`;

export const CREATE_TICKET_MUTATION = gql`
	mutation AddTicket(
		$ticketNumber: String!
		$name: String
		$message: String
		$email: String
		$dateCreation: DateTime
	) {
		addTicket(
			ticketNumber: $ticketNumber
			name: $name
			message: $message
			email: $email
			dateCreation: $dateCreation
		) {
			id
			ticketNumber
			name
			message
			email
			dateCreation
		}
	}
`;

export const CREATE_USER_MUTATION2 = gql`
	mutation CreateUser2(
		$email: String!
		$lastName: String
		$firstName: String
		$mobileNumber: String!
	) {
		createUser2(
			email: $email
			lastName: $lastName
			firstName: $firstName
			mobileNumber: $mobileNumber
		) {
			id
			email
			firstName
			lastName
			mobileNumber
			role
		}
	}
`;

export const DISPLAY_DATA_MUTATION = gql`
	mutation DisplayData(
		$memberId: ID!
		$showName: Boolean
		$showAddress: Boolean
		$showEmail: Boolean
		$showLandline: Boolean
		$showMobileNumber: Boolean
		$showDays: Boolean
		$showHours: Boolean
	) {
		displayData(
			memberId: $memberId
			showName: $showName
			showAddress: $showAddress
			showEmail: $showEmail
			showLandline: $showLandline
			showMobileNumber: $showMobileNumber
			showDays: $showDays
			showHours: $showHours
		) {
			id
			showAddress
			showEmail
			showLandline
			showMobileNumber
			showDays
			showHours
		}
	}
`;

export const CREATE_LOG_MUTATION = gql`
	mutation addLog(
		$email: String
		$date: DateTime
		$screen: String
		$log: String
		$remarks: String
	) {
		addLog(
			email: $email
			date: $date
			screen: $screen
			log: $log
			remarks: $remarks
		) {
			id
			email
		}
	}
`;
export const CREATE_SEARCHVIEW_MUTATION = gql`
	mutation addSearchView(
		$emailMobile: String
		$country: String
		$province: String
		$city: String
		$ip: String
	) {
		addSearchView(
			emailMobile: $emailMobile
			country: $country
			province: $province
			city: $city
			ip: $ip
		) {
			id
			email
			mobileNumber
			date
			country
			province
			city
			ip
		}
	}
`;

export const CREATE_CLICK_MUTATION = gql`
	mutation addClick(
		$emailMobile: String
		$country: String
		$province: String
		$city: String
		$ip: String
		$userEmail: String
	) {
		addClick(
			emailMobile: $emailMobile
			country: $country
			province: $province
			city: $city
			ip: $ip
			userEmail: $userEmail
		) {
			id
			email
			mobileNumber
			date
			country
			province
			city
			ip
			userEmail
		}
	}
`;

export const CREATE_NOTARY_RATING = gql`
	mutation createNotaryRating(
		$userId: ID!
		$memberId: ID!
		$userSearchTxId: ID!
		$accessible: Float
		$friendly: Float
		$fast: Float
		$review: String
		$date: DateTime
	) {
		createNotaryRating(
			userId: $userId
			memberId: $memberId
			userSearchTxId: $userSearchTxId
			accessible: $accessible
			friendly: $friendly
			fast: $fast
			review: $review
			date: $date
		) {
			id
		}
	}
`;

export const CREATE_USER_SEARCH_TEXT_INITIAL = gql`
	mutation createInitialUserSearchTx(
		#origin
		# $province: String
		# $city: String
		# $subCityMunicipality: String
		# $barangay: String
		# $streetNumber: String
		$lng: Float
		$lat: Float
		$date: DateTime!
		# $documentType: String # deed of sale, lease, etc
		# $amount: Float
		# $price: Float
		# $memberId: ID
		$userId: ID!
		# $documentServiceId: ID
		# $isUserNotSignatory: Boolean
		$documentFile: [DocumentFileInput]
		$invoiceNo: String!
		# $bookingId: String
		$status: String # $datePaid: DateTime # $signatories: [SignatoryInput!] # $payment: PaymentInput
	) {
		createUserSearchTx_InitialBooking(
			#origin
			# province: $province
			# city: $city
			# subCityMunicipality: $subCityMunicipality
			# barangay: $barangay
			# streetNumber: $streetNumber
			lng: $lng
			lat: $lat
			date: $date
			#documentType: $documentType # deed of sale, lease, etc
			# amount: $amount
			# price: $price
			# memberId: $memberId
			userId: $userId
			# documentServiceId: $documentServiceId
			# isUserNotSignatory: $isUserNotSignatory
			documentFile: $documentFile

			invoiceNo: $invoiceNo
			# bookingId: $bookingId
			status: $status # datePaid: $datePaid # signatories: $signatories # payment: $payment
		) {
			id
			date
			invoiceNo
			lat
			lng
			user {
				id
				firstName
				lastName
			}
			status
			bookingStatus {
				id
				status
				date
			}
			documentService {
				id
				documentTemplate {
					documentTypeLevel2
					displayAmount
					documentTopLevel {
						documentTypeLevel1
					}
				}
				rate
				fixedFee
			}
		}
	}
`;

export const SET_LAST_LOCATION = gql`
	mutation setLastLocation(
		$email: String!
		$lastLocation: String
		$lastLocationLat: Float
		$lastLocationLng: Float
		$justRegistered: Boolean
	) {
		setLastLocation(
			email: $email
			lastLocation: $lastLocation
			lastLocationLat: $lastLocationLat
			lastLocationLng: $lastLocationLng
			justRegistered: $justRegistered
		) {
			email
			lastLocation
			lastLocationLat
			lastLocationLng
			justRegistered
		}
	}
`;

export const SET_BOOKING_AS_VIEWED = gql`
	mutation setBookingAsViewed($userSearchTextId: ID!) {
		setBookingAsViewed(userSearchTextId: $userSearchTextId) {
			id
		}
	}
`;

export const CREATE_PAYMENT_STUB = gql`
	mutation createPaymentStub(
		$paymentIntentSourceId: String
		$payableToMember: Int
		$commission: Int
		$memberId: ID
		$userSearchTextId: ID
	) {
		createPaymentStub(
			paymentIntentSourceId: $paymentIntentSourceId
			payableToMember: $payableToMember
			commission: $commission
			memberId: $memberId
			userSearchTextId: $userSearchTextId
		) {
			id
			member {
				id
			}
			booking {
				id
				bookingStatus {
					id
					status
					date
				}
			}
			date
			paymentIntentSourceId
			commission
		}
	}
`;

export const FAIL_PAYMENT_STUB = gql`
	mutation failPaymentStub($paymentStubId: ID, $remarks: String) {
		failPaymentStub(paymentStubId: $paymentStubId, remarks: $remarks) {
			id
			date
			bookingId
			paymentIntentSourceId
			paymentRefId
			payableToMember
			remarks
			status
			commission
			member {
				id
			}
			booking {
				id
				bookingStatus {
					id
					status
					date
				}
			}
		}
	}
`;

export const CREATE_USER_SEARCH_TEXT_DESCRIBE_INITIAL = gql`
	mutation createUserSearchText_DescribeInitial(
		#origin
		$province: String
		$city: String
		$subCityMunicipality: String
		$barangay: String
		$streetNumber: String
		$lng: Float
		$lat: Float
		$amount: Float
		$price: Float
		$memberId: ID
		$userId: ID!
		$documentServiceId: ID
		$invoiceNo: String
		$docType: String
	) {
		createUserSearchText_DescribeInitial(
			province: $province
			city: $city
			subCityMunicipality: $subCityMunicipality
			barangay: $barangay
			streetNumber: $streetNumber
			lng: $lng
			lat: $lat
			amount: $amount
			price: $price
			memberId: $memberId
			documentServiceId: $documentServiceId
			userId: $userId
			invoiceNo: $invoiceNo
			docType: $docType
		) {
			id
			date
			invoiceNo
			user {
				id
			}
			member {
				id
			}
			documentService {
				id
				documentTemplate {
					documentTypeLevel2
					displayAmount
					documentTopLevel {
						documentTypeLevel1
					}
				}
			}
			lat
			lng
			amount
			price
		}
	}
`;

export const UPDATE_BOOKING_FORPAYMENT = gql`
	mutation updateUserSearchTextForPayment(
		$userSearchTextId: ID!
		$memberId: ID
		$documentServiceId: ID
		$price: Float
	) {
		updateUserSearchTextForPayment(
			userSearchTextId: $userSearchTextId
			memberId: $memberId
			documentServiceId: $documentServiceId
			price: $price
		) {
			id
			member {
				id
			}
			user {
				id
			}
			transactionId
			invoiceNo
			amount
			price
			lat
			lng
			documentService {
				id
				documentTemplate {
					documentTypeLevel2
					displayAmount
					documentTopLevel {
						documentTypeLevel1
					}
				}
				rate
				fixedFee
			}
		}
	}
`;

export const SEND_BOOKINGCOMPLETE_EMAIL = gql`
	mutation sendThankYouBookingEmail(
		$bookingId: ID!
		$businessHoursHtml: String
		$bookingLinkUser: String
		$contactLink: String
		$bookingLinkMember: String
	) {
		sendThankYouBookingEmail(
			bookingId: $bookingId
			businessHoursHtml: $businessHoursHtml
			bookingLinkUser: $bookingLinkUser
			contactLink: $contactLink
			bookingLinkMember: $bookingLinkMember
		) {
			retValue
		}
	}
`;

export const DENY_BOOKING_NOTARY = gql`
	mutation denyBookingNotary($userSearchTextId: ID!, $reasonForDeny: String!) {
		denyBookingNotary(
			userSearchTextId: $userSearchTextId
			reasonForDeny: $reasonForDeny
		) {
			id
			status
			reasonForCancel
			transactionId
			invoiceNo
			member {
				id
				email
				lastName
				firstName
			}
			user {
				id
				email
				lastName
				firstName
				mobileNumber
			}
			payment {
				id
				paymentRefId
				paymentIntentSourceId
				amount
				netAmount
				fee
				foreignFee
				commission
        netIncome {
          id 
          revenue
        }
			}
		}
	}
`;

export const CANCEL_BOOKING_PRINCIPAL = gql`
	mutation cancelBookingPrincipal($userSearchTextId: ID!) {
		cancelBookingPrincipal(userSearchTextId: $userSearchTextId) {
			id
			status
			transactionId
			invoiceNo
			member {
				id
				email
				lastName
				firstName
			}
			user {
				id
				email
				lastName
				firstName
				mobileNumber
			}
		}
	}
`;

export const UPDATE_FEES_RATES = gql`
	mutation updateFeesRates($memberId: ID!, $rowSet: String!) {
		updateFeesRates(memberId: $memberId, rowSet: $rowSet) {
			retValue
		}
	}
`;

export const NOTARIZE_BOOKING = gql`
	mutation completeBookingNotary($userSearchTextId: ID!) {
		completeBookingNotary(userSearchTextId: $userSearchTextId) {
			id
			status
			transactionId
			invoiceNo
			user {
				id
				mobileNumber
				email
				firstName
				lastName
			}
			member {
				id
				email
				mobileNumber
				firstName
				lastName
			}
		}
	}
`;

export const CREATE_APPOINTMENT = gql`
	mutation createAppointment(
		$memberId: ID
		$userId: ID
		$userSearchTxId: ID
		$title: String
		$start: DateTime
		$end: DateTime
		$resource: String
	) {
		createAppointment(
			memberId: $memberId
			userId: $userId
			userSearchTxId: $userSearchTxId
			title: $title
			start: $start
			end: $end
			resource: $resource
		) {
			id
			status
			member {
				id
				email
				lastName
				firstName
				streetNumber
				barangay
				cityMunicipality
				province
				city
				mobileNumber
			}
			user {
				id
				email
				lastName
				firstName
			}
			booking {
				id
				date
				price
				amount
				status
				reasonForCancel
				member {
					id
					email
					lastName
					firstName
					streetNumber
					barangay
					cityMunicipality
					province
					city
					mobileNumber
				}
				payoutToMember {
					payableToMember
				}
				documentService {
					isPercentage
					documentTemplate {
						documentTypeLevel2
						documentTopLevel {
							documentTypeLevel1
						}
					}
				}
			}
			title
			start
			end
		}
	}
`;

export const DELETE_APPOINTMENT = gql`
	mutation deleteAppointment($id: ID) {
		deleteAppointment(id: $id) {
			id
		}
	}
`;

export const CANCEL_APPOINTMENT = gql`
	mutation cancelAppointment($id: ID) {
		cancelAppointment(id: $id) {
			id
			status
			dateCancelled
			member {
				id
				email
				lastName
				firstName
				streetNumber
				barangay
				cityMunicipality
				province
				city
				mobileNumber
			}
			user {
				id
				email
				lastName
				firstName
			}
			booking {
				id
				date
				price
				amount
				status
				reasonForCancel
				payoutToMember {
					payableToMember
				}
				documentService {
					isPercentage
					documentTemplate {
						documentTypeLevel2
						documentTopLevel {
							documentTypeLevel1
						}
					}
				}
			}
			title
			start
			end
		}
	}
`;

export const SEND_APPOINTMENT_SET_EMAIL = gql`
	mutation sendAppointmentEmailToMember($userSearchTextId: ID!) {
		sendAppointmentEmailToMember(userSearchTextId: $userSearchTextId) {
			id
			status
			dateCancelled
			member {
				id
				email
				lastName
				firstName
				streetNumber
				barangay
				cityMunicipality
				subCityMunicipality
				province
				city
				mobileNumber
			}
			user {
				id
				email
				firstName
				lastName
				mobileNumber
			}
			booking {
				id
				transactionId
				invoiceNo
				date
				price
				amount
				status
				reasonForCancel
				member {
					id
					email
					lastName
					firstName
					streetNumber
					barangay
					cityMunicipality
					subCityMunicipality
					province
					city
					mobileNumber
				}
				payoutToMember {
					payableToMember
				}
				documentService {
					isPercentage
					documentTemplate {
						documentTypeLevel2
						documentTopLevel {
							documentTypeLevel1
						}
					}
				}
			}
			title
			start
			end
		}
	}
`;

export const CREATE_BLOCKEDOFF_TEMPLATE = gql`
	mutation createBlockedOffTemplate(
		$memberId: ID
		$start: DateTime
		$end: DateTime
	) {
		createBlockedOffTemplate(memberId: $memberId, start: $start, end: $end) {
			id
			day
			start
			end
		}
	}
`;

export const CREATE_MULTIPLE_BLOCKEDOFF_TEMPLATE = gql`
	mutation createBlockedOffTemplateMultipleDays(
		$memberId: ID!
		$slotInfoStart: DateTime!
		$slotInfoEnd: DateTime!
		$days: [String!]!
	) {
		createBlockedOffTemplateMultipleDays(
			memberId: $memberId
			slotInfoStart: $slotInfoStart
			slotInfoEnd: $slotInfoEnd
			days: $days
		) {
			id
			day
			start
			end
		}
	}
`;

export const DELETE_BLOCKEDOFF_TEMPLATES = gql`
	mutation deleteBlockedOffTemplates($memberId: ID) {
		deleteBlockedOffTemplates(memberId: $memberId) {
			retValue
		}
	}
`;

export const DELETE_BLOCKEDOFFTEMPLATE = gql`
	mutation deleteBlockedOffTemplate($id: ID) {
		deleteBlockedOffTemplate(id: $id) {
			retValue
		}
	}
`;

export const DELETE_THIS_BLOCKEDOFF = gql`
	mutation deleteBlockedOffThis($id: ID) {
		deleteBlockedOffThis(id: $id) {
			retValue
		}
	}
`;

export const DELETE_BLOCKEDOFF_SCHED = gql`
	mutation deleteBlockedOffSched(
		$memberId: ID
		$today: DateTime
		$weekAfter: DateTime
	) {
		deleteBlockedOffSched(
			memberId: $memberId
			today: $today
			weekAfter: $weekAfter
		) {
			retValue
		}
	}
`;

export const DELETE_BLOCKEDOFF = gql`
	mutation deleteBlockedOff($memberId: ID, $start: DateTime) {
		deleteBlockedOff(memberId: $memberId, start: $start) {
			retValue
		}
	}
`;

export const CREATE_BLOCKEDOFF = gql`
	mutation createBlockedOffSched(
		$memberId: ID
		$start: DateTime
		$end: DateTime
	) {
		createBlockedOffSched(memberId: $memberId, start: $start, end: $end) {
			id
			day
			start
			end
		}
	}
`;

export const SEND_MOVED_APPOINTMENT_EMAIL = gql`
	mutation sendMovedAppointmentEmail(
		$initialAppointmentId: ID
		$initialAppointmentStart: String
		$initialAppointmentEnd: String
		$userSearchTextId: ID!
	) {
		sendMovedAppointmentEmail(
			initialAppointmentId: $initialAppointmentId
			initialAppointmentStart: $initialAppointmentStart
			initialAppointmentEnd: $initialAppointmentEnd
			userSearchTextId: $userSearchTextId
		) {
			id
			status
			dateCancelled
			member {
				id
				email
				lastName
				firstName
				streetNumber
				barangay
				cityMunicipality
				subCityMunicipality
				province
				city
				mobileNumber
			}
			user {
				id
				email
				firstName
				lastName
				mobileNumber
			}
			booking {
				id
				transactionId
				invoiceNo
				date
				price
				amount
				status
				reasonForCancel
				member {
					id
					email
					lastName
					firstName
					streetNumber
					barangay
					cityMunicipality
					subCityMunicipality
					province
					city
					mobileNumber
				}
				payoutToMember {
					payableToMember
				}
				documentService {
					isPercentage
					documentTemplate {
						documentTypeLevel2
						documentTopLevel {
							documentTypeLevel1
						}
					}
				}
			}
			title
			start
			end
		}
	}
`;

export const UPDATE_DOC_SERVICE_MEMBER = gql`
	mutation updateDocumentServiceMember(
		$documentServiceId: ID!
		$enabled: Boolean
		$rate: Float
		$fixedFee: Float
		$priceCalc: String
	) {
		updateDocumentServiceMember(
			documentServiceId: $documentServiceId
			enabled: $enabled
			rate: $rate
			fixedFee: $fixedFee
			priceCalc: $priceCalc
		) {
			id
			rate
			fixedFee
			enabled
			documentClass {
				id
				description
				ord
				priceCalc
			}
		}
	}
`;

export const UPDATE_FROM_LOG_IN = gql`
	mutation updatelogIn($id: ID, $latestToken: String) {
		updatelogIn(id: $id, latestToken: $latestToken) {
			id
			latestToken
			email
			token {
				id
				token
			}
			# isLoggedIn
			# loginDate
		}
	}
`;

export const SEND_NOTARY_INQUIRY = gql`
	mutation sendNotaryInquiryEmail(
		$name: String!
		$email: String!
		$mobileNumber: String!
	) {
		sendNotaryInquiryEmail(
			name: $name
			email: $email
			mobileNumber: $mobileNumber
		) {
			retValue
		}
	}
`;

export const CREATE_REFUND_STUB = gql`
	mutation createRefundStub(
		$userSearchTxId: ID!
		$amount: Float!
		$refundId: String
		$memberId: ID!
		# $userId: ID!
		$paymentId: String!
		$refundType: String
    $remarks: String
    $status: String
	) {
		createRefundStub(
			userSearchTxId: $userSearchTxId
			amount: $amount
			refundId: $refundId
			memberId: $memberId
			# userId: $userId!,
			paymentId: $paymentId
			refundType: $refundType
      remarks: $remarks
      status: $status
		) {
			id
			amount
			refundId
			refundType
			date
      remarks
      status
		}
	}
`;
