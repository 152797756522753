import React from 'react';

import classNames from 'classnames';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
// import Button from 'components/CustomButtons/Button.js';
import Email from '@material-ui/icons/Email';
import Check from '@material-ui/icons/Check';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Footer from 'components/Footer/Footer.js';
import Parallax from 'components/Parallax/Parallax.js';

import AuthUserContext from './context';
import { withFirebase } from 'utils/Firebase';

import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import defaultMaterialTheme from 'utils/Themes/defaultMaterialTheme';
import defaultBtnMaterialTheme from 'utils/Themes/defaultBtnMaterialTheme';
import {
	primaryColor,
	grayColor,
	blackColor,
	whiteColor,
} from 'assets/jss/material-kit-pro-react.js';

import { gql } from '@apollo/client';
import useWindowSize from 'utils/useWindowSize';
import Waiting from 'utils/Waiting';
import Transition from 'utils/Transition';
import PaperComponent from 'utils/PaperComponent';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import LineStyle from '@material-ui/icons/LineStyle';

import aboutPageMaterialTheme from 'utils/Themes/aboutPageMaterialTheme';

export const UPDATE_MEMBER_AS_ACTIVE = gql`
	mutation updateMemberAsActive($email: String!) {
		updateMemberAsActive(email: $email) {
			id
			email
		}
	}
`;

const needsEmailVerification = (authUser) =>
	authUser &&
	!authUser.emailVerified &&
	authUser.providerData
		.map((provider) => provider.providerId)
		.includes('password');
// &&
// authUser.providerData
//   .map(provider => provider.providerId)
//   .includes('roles');

const withEmailVerification = (Component) => {
	class WithEmailVerification extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				isSent: false,
				_isOpenWaiting_: false,
				confirmationSent: false,
				termsOfService: false,
				dataPrivacyPolicy: false,
			};
		}

		onSendEmailVerification = () => {
			console.log(`onSendEmailVerification`);
			// this.props.firebase
			//   .doSendEmailRedirect()
			//   .then(() => this.setState({ isSent: true }));
		};

		componentWillMount = async () => {
			// console.log('start withEmailVerification.componentWillMount');
			// console.log('end withEmailVerification.componentWillMount');
		};

		componentDidMount = async () => {
			// console.log('start withEmailVerification.componentDidMount');
			// console.log('end withEmailVerification.componentDidMount');
		};

		componentDidUpdate = async () => {
			// console.log('start withEmailVerification.componentDidUpdate');
			// console.log('end withEmailVerification.componentDidUpdate');
		};

		render() {
			// console.log('start withEmailVerification.render');
			const { classes } = this.props;
			// console.log('end withEmailVerification.render');
			return (
				<>
					<AuthUserContext.Consumer>
						{(authUser) =>
							needsEmailVerification(authUser) ? (
								<React.Fragment>
									<Parallax
										style={{ marginTop: 0 }}
										// image={require('assets/img/bg10.jpg')}
										// className={classes.parallax}
									>
										<div className={classes.container}>
											<GridContainer justify="center">
												<GridItem xs={12} sm={6} md={6}>
													<Card className={classNames(classes.mainRaised)}>
														<CardBody>
															<GridContainer justify="center">
																<GridItem xs={12} sm={10} md={10}>
																	{!authUser.emailSent ||
																	!this.state._isOpenWaiting_ ? (
																		<React.Fragment>
																			{/* {`${JSON.stringify(authUser)}`} */}

																			<InfoArea
																				className={classes.infoArea}
																				title="Email confirmation sent"
																				description=""
																				icon={Email}
																				iconColor="primary"
																			/>
																			<div
																				className={classes.container}
																				style={{
																					fontSize: '16px',
																					fontWeight: '400',
																					color: 'blackColor',
																				}}
																			>
																				You have almost completed your
																				registration as a Notary Member for
																				NP360.
																				<br />
																				<br />
																				<p
																					style={{
																						fontSize: '16px',
																						color: 'blackColor',
																					}}
																				>
																					Important: validate your email address
																					now. Please check your inbox or spam
																					folder to find the validation email
																					that we sent (Subject: Verify your
																					email for Notary Public 360.").{' '}
																				</p>
																				<br />
																				<p
																					style={{
																						fontSize: '16px',
																						color: 'blackColor',
																					}}
																				>
																					Open the email and click the
																					validation link. If you cannot find
																					the validation email,&nbsp;
																					<text
																						style={{
																							color: primaryColor[0],
																							textDecoration: 'underline',
																							cursor: 'pointer',
																						}}
																						onClick={() => {
																							this.setState({
																								_isOpenWaiting_: true,
																							});
																							// alert(`onSendEmailVerification`)
																							this.props.firebase
																								.doSendEmailRedirect()
																								.then(() => {
																									this.setState({
																										isSent: true,
																									});
																								});
																							setTimeout(() => {
																								this.setState({
																									_isOpenWaiting_: false,
																									confirmationSent: true,
																								});
																								setTimeout(() => {
																									this.setState({
																										confirmationSent: false,
																									});
																								}, 2000);
																							}, 5000);
																						}}
																					>
																						click this to have it resent
																					</text>
																					&nbsp;or send us an email at{' '}
																					<div
																						style={{ color: primaryColor[0] }}
																					>
																						business@notarypublic360.com.
																					</div>{' '}
																				</p>
																				<br />
																			</div>
																		</React.Fragment>
																	) : null}
																</GridItem>
															</GridContainer>
														</CardBody>
														<br />
														<br />
														<br />
													</Card>
												</GridItem>
											</GridContainer>
										</div>
									</Parallax>
								</React.Fragment>
							) : authUser &&
							  authUser.emailVerified &&
							  authUser.providerData
									.map((provider) => provider.providerId)
									.includes('password') &&
							  authUser.providerData
									.map((provider) => provider.displayName)
									.includes('NP360° Member') ? (
								<Component {...this.props} />
							) : (
								<React.Fragment>
									<Parallax
										style={{ marginTop: 0 }}
										// image={require('assets/img/bg10.jpg')}
										// className={classes.parallax}
									>
										<div className={classes.container}>
											<GridContainer justify="center">
												<GridItem xs={12} sm={6} md={6}>
													<Card className={classNames(classes.mainRaised)}>
														<CardBody>
															<GridContainer justify="center">
																<GridItem xs={12} sm={10} md={10}>
																	{!authUser.emailSent ||
																	!this.state._isOpenWaiting_ ? (
																		<React.Fragment>
																			{/* {`${JSON.stringify(authUser)}`} */}
																			{/* {`${JSON.stringify(this.props.firebase.auth.currentUser)}`} */}

																			<InfoArea
																				className={classes.infoArea}
																				title="Welcome to NP360°"
																				description=""
																				icon={Check}
																				iconColor="primary"
																			/>
																			<div
																				className={classes.container}
																				style={{
																					fontSize: '16px',
																					fontWeight: '400',
																					color: 'blackColor',
																				}}
																			>
																				Congratulations! <br />
																				<br />
																				<p
																					style={{
																						fontSize: '16px',
																						color: 'blackColor',
																					}}
																				>
																					You are among the first Notary Members
																					of NP360°. You get free NP360°
																					membership until the expiry of your
																					current notarial commission.{' '}
																				</p>
																				<br />
																				<span style={{ color: 'black' }}>
																					{`By registering with NP360°, you agree to the`}
																					&nbsp;
																				</span>
																				<text
																					style={{
																						color: primaryColor[0],
																						textDecoration: 'underline',
																						cursor: 'pointer',
																					}}
																					onClick={() => {
																						this.setState({
																							termsOfService: true,
																						});
																					}}
																				>
																					NP360° Terms of Service
																				</text>
																				<span style={{ color: 'black' }}>
																					{' '}
																					{` and the `}
																				</span>
																				<text
																					style={{
																						color: primaryColor[0],
																						textDecoration: 'underline',
																						cursor: 'pointer',
																					}}
																					onClick={() => {
																						this.setState({
																							dataPrivacyPolicy: true,
																						});
																					}}
																				>
																					NP360° Data Privacy Policy.
																				</text>
																				<br />
																			</div>
																		</React.Fragment>
																	) : null}
																</GridItem>
															</GridContainer>
															<ThemeProvider theme={defaultBtnMaterialTheme}>
																<Button
																	style={{
																		width: 200,
																		textAlign: 'center',
																		marginTop: '40px',
																		marginBottom: '10px',
																		display: 'block',
																		marginLeft: 'auto',
																		marginRight: 'auto',
																	}}
																	variant="contained"
																	color="primary"
																	size="sm"
																	onClick={(e) => {
																		e.preventDefault();
																		debugger;
																		this.props.firebase.auth.currentUser
																			.updateProfile({
																				displayName: 'NP360° Member',
																			})
																			.then(async () => {
																				debugger;
																				this.props.client
																					.mutate({
																						mutation: UPDATE_MEMBER_AS_ACTIVE,
																						variables: {
																							email: authUser.email,
																						},
																					})
																					.then((result) => {
																						debugger;
																					})
																					.catch((error) => {
																						console.log(
																							'error: UPDATE_MEMBER_AS_ACTIVE ',
																							error
																						);
																					});

																				this.props.firebase
																					.user(authUser.uid)
																					.set(
																						{
																							displayName: 'NP360° Member',
																						},
																						{ merge: true }
																					)
																					.then(() => {
																						// this.props.history.push("/")
																						window.location.reload();
																					})
																					.catch((error) => {
																						this.setState({ error });
																						console.log(
																							'error: .props.firebase.user' +
																								error
																						);
																					});
																			})
																			.catch((error) => {
																				this.setState({ error });
																				console.log(
																					'error: .props.firebase.auth.currentUser.updateProfile' +
																						error
																				);
																			});
																	}}
																>
																	Proceed
																</Button>
															</ThemeProvider>
														</CardBody>
														<br />
														<br />
														<br />
													</Card>
												</GridItem>
											</GridContainer>
										</div>
									</Parallax>
								</React.Fragment>
							)
						}
					</AuthUserContext.Consumer>

					<Dialog
						open={this.state._isOpenWaiting_}
						// onClose={handleClose}
						PaperComponent={PaperComponent}
						aria-labelledby="draggable-dialog-title"
					>
						<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
							{' '}
						</DialogTitle>
						<DialogContent>
							<div id="waitingD" style={{ marginTop: -5, marginBottom: 10 }}>
								<Waiting
									Transition={Transition}
									message={'Sending ...'}
									isOpenWaiting={this.state._isOpenWaiting_}
								/>
							</div>
							<DialogContentText></DialogContentText>
						</DialogContent>
					</Dialog>

					<Dialog
						open={this.state.confirmationSent}
						// onClose={handleClose}
						PaperComponent={PaperComponent}
						aria-labelledby="draggable-dialog-title"
					>
						<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
							{' '}
						</DialogTitle>
						<DialogContent>
							<text
								style={{
									fontSize: 16,
									fontWeight: 'bold',
									color: blackColor,
									cursor: 'pointer',
								}}
								onClick={() => {
									this.setState({ confirmationSent: false });
								}}
							>
								Confirmation sent.
							</text>
						</DialogContent>
						<br />
					</Dialog>

					<Dialog
						open={this.state.dataPrivacyPolicy}
						// onClose={handleClose}
						PaperComponent={PaperComponent}
						aria-labelledby="draggable-dialog-title"
					>
						<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
							{'Data Privacy Policy'}
						</DialogTitle>
						<DialogContent>
							<ThemeProvider theme={defaultMaterialTheme}>
								<div
									className={classes.container}
									id="mainAbout"
									style={{
										// justifyContent: 'center',
										margin: '20px 0px 0px 0px',
									}}
								>
									<div className={classes.container}>
										{/* {handleChangePageContent()} */}
										<div
											className={classes.container}
											style={{
												marginLeft: -20,
												// width: `${size.width <= 414 ? size.width - 30 : 600}px`,
												overflow: 'auto',
												// height: `${size.height - 190}px`,
												marginBottom: 20,
											}}
										>
											<ThemeProvider theme={aboutPageMaterialTheme}>
												<div className={classes.container}>
													<Typography
														style={{
															fontSize: '24px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														WHAT INFORMATION DO WE COLLECT?
													</Typography>
													<Typography
														style={{
															fontSize: '14px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														Provider collects information from user when user
														registers on Provider’s site and when user provides
														details of his or her work as a notary public. When
														registering on Provider’s site, as appropriate,
														member shall be asked to enter name, email address,
														phone number and qualification details and related
														data.
														<p />
														Provider may contract with third-party service
														providers to assist Provider in better understanding
														its site visitors. These service providers are not
														permitted to use the information collected on its
														behalf except to help Provider conduct and improve
														its business.
													</Typography>

													<Typography
														style={{
															fontSize: '24px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														WHAT DO WE USE YOUR INFORMATION FOR?
													</Typography>
													<Typography
														style={{
															fontSize: '14px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														Any of the information collected from user may be
														used in one of the following ways: to personalize
														user experience (user information helps Provider to
														better respond to user’s individual needs), to
														improve Provider’s website, to improve customer
														service and to process transactions.
														<p />
														User information, whether public or private, will
														not be sold, exchanged, transferred, or given to any
														other company or entity for any reason whatsoever,
														without user’s consent, other than for the express
														purpose of delivering the service requested. User
														information may be shared by Provider with its
														affiliates to improve customer service and to
														process transactions.
														<p />
														The email address user provides shall only be used
														to send information and updates pertaining to the
														service.
													</Typography>

													<Typography
														style={{
															fontSize: '24px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														HOW DO WE PROTECT YOUR INFORMATION?
													</Typography>
													<Typography
														style={{
															fontSize: '14px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														Provider fully complies with the provisions of
														Republic Act No. 10173 otherwise known as the “Data
														Privacy Act of 2012”. Provider implements a variety
														of security measures to maintain the safety of
														user’s personal information when user registers,
														enters information in the directory, accesses
														personal information or enters information needed to
														search for a suitable notary.
														<p />
														Provider protects all of user’s information through
														encryption, cryptographic protocol transmission and
														uses foreign-based cloud servers for added
														protection.
														<p />
														Personnel who work at <strong>NP360°</strong> are
														made to understand how to be security conscious and
														recognize suspicious activity.{' '}
														<strong>NP360°</strong> employees are required to
														acknowledge security policies prior to being granted
														systems access. Employees also take part in
														mandatory security and privacy training for new
														hires and receive regular security awareness
														training via informational emails, talks,
														presentations, and resources available on our
														internal knowledge base. <strong>NP360°</strong>{' '}
														will notify User in the event of a data breach, as
														required by applicable law. Provider maintains
														incident response policies and procedures, including
														a breach notification process, which enables
														Provider to notify affected customers and the Data
														Privacy Commission, as needed.
													</Typography>

													<Typography
														style={{
															fontSize: '24px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														DO WE DISCLOSE ANY INFORMATION TO OUTSIDE PARTIES?
													</Typography>
													<Typography
														style={{
															fontSize: '14px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														Provider does not sell, trade, or otherwise transfer
														to parties outside of its affiliates user’s
														personally identifiable information. Provider shall
														only release user information when this is necessary
														to comply with the law or a court order, to enforce
														Provider policies, or protect Provider’s or others
														rights, property, or safety. However, aggregated,
														non-personally identifiable user information may be
														provided to other parties for marketing,
														advertising, or other uses.
														<p />
														<strong>
															In addition, all information that is provided to
															the Service by a Member pertaining to a
															professional engagement with a client is
															privileged communication since it is covered by
															the attorney-client privilege. Provider or any of
															its officers or employees may only divulge such
															information if there is consent of the client and
															the subscriber or if ordered to do so by the
															judicial authorities, despite the existence of the
															said privilege.
														</strong>
													</Typography>

													<Typography
														style={{
															fontSize: '24px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														CAN USERS ACCESS, CORRECT AND/OR DELETE THEIR
														INFORMATION?
													</Typography>
													<Typography
														style={{
															fontSize: '14px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														Provider respects User’s privacy rights and provides
														User with reasonable access to the Personal Data
														that User may have provided through the use of the
														Services. User may access or amend any Personal
														Data, or request to delete or to transfer any
														information about User.
														<p />
														Users may update, correct, or delete account
														information at any time by accessing account
														settings page on the Service. Provider may retain
														all information User submits for backups, archiving,
														prevention of fraud and abuse, analytics,
														satisfaction of legal obligations, or where Provider
														otherwise reasonably believes that there is a
														legitimate reason to do so.
														<p />
														User may decline to share certain Personal Data, in
														which case Provider may not be able to provide some
														of the features and functionality of the Service.
														<p />
														At any time, User may object to the processing of
														his Personal Data, on legitimate grounds, except if
														otherwise permitted by applicable law. For any
														objection on the processing of personal data or any
														violation of rights under the Data Privacy Act,
														please contact us immediately.
													</Typography>

													<Typography
														style={{
															fontSize: '24px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														IS USER NOTIFIED IN CASE OF BREACH OF DATA?
													</Typography>
													<Typography
														style={{
															fontSize: '14px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														In general, User is notified in case of breach of
														data but Provider does not have to notify the data
														subjects if the breach involves anonymized data or
														any personal information that cannot be used for
														identity fraud. Specifically, the notice to data
														subjects is not required if the data controller has
														implemented pseudonymization techniques like
														encryption along with adequate technical and
														organizational protection measures to the personal
														data affected by the data breach.
													</Typography>

													<Typography
														style={{
															fontSize: '24px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														CAN USER CHOOSE NOT TO HAVE HIS OR HER PERSONAL
														INFORMATION INCLUDED IN GOOGLE ANALYTICS?
													</Typography>
													<Typography
														style={{
															fontSize: '14px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														The Google Analytics service is provided by Google
														Inc. User can opt-out from Google Analytics service
														from using User information by installing the Google
														Analytics Opt-out Browser tool: {' '}
														<a href="https://tools.google.com/dlpage/gaoptout">
															tools.google.com/dlpage/gaoptout.
														</a>{' '}
														For more information on the privacy practices of
														Google, please visit the Google Privacy & Terms web
														page: {' '}
														<a href="https://www.google.com/policies/privacy">
															www.google.com/policies/privacy.
														</a>
													</Typography>
												</div>
											</ThemeProvider>
										</div>
										<div className={classes.container}>
											<DialogActions className={classes.modalFooter}>
												<ThemeProvider theme={defaultBtnMaterialTheme}>
													<Button
														style={{
															width: '100%',
														}}
														variant="contained"
														color="primary"
														size="md"
														// onClick={open ? handleClose : null}
														// href={open ? null : `/app/dashboard`}
														onClick={(e) => {
															e.preventDefault();
															this.setState({ dataPrivacyPolicy: false });
														}}
													>
														BACK
													</Button>
												</ThemeProvider>
											</DialogActions>
										</div>
									</div>
								</div>
							</ThemeProvider>
						</DialogContent>
					</Dialog>

					<Dialog
						open={this.state.termsOfService}
						// onClose={handleClose}
						PaperComponent={PaperComponent}
						aria-labelledby="draggable-dialog-title"
					>
						<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
							{'Terms of Service '}
						</DialogTitle>
						<DialogContent>
							<ThemeProvider theme={defaultMaterialTheme}>
								<div
									className={classes.container}
									id="mainAbout"
									style={{
										// justifyContent: 'center',
										margin: '20px 0px 0px 0px',
									}}
								>
									<div className={classes.container}>
										{/* {handleChangePageContent()} */}
										<div
											className={classes.container}
											style={{
												marginLeft: -20,
												// width: `${size.width <= 414 ? size.width - 30 : 600}px`,
												overflow: 'auto',
												// height: `${size.height - 170}px`,
												marginBottom: 20,
											}}
										>
											<ThemeProvider theme={aboutPageMaterialTheme}>
												<div className={classes.container}>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														This document governs your registration and
														membership with Notary Public 360° (or NP360°, for
														brevity). The NP360° Service ("Service") is provided
														by BillYouNow Corporation (the “Provider”).
													</Typography>
													<Typography
														style={{
															fontSize: '20px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														Overview
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														1. A notary may, via the Service, make notarial
														services available to clients who search for
														notaries online.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														2. The Service is a digital online platform that
														enables clients to search for notaries, book, pay
														and set or request appointments for notarial
														service.
													</Typography>
													<Typography
														style={{
															fontSize: '20px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														Privacy and Security
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														3. By registering for the Service, a notary agrees
														to Provider's privacy and security policies as
														published on its website, including all prospective
														versions thereof.
													</Typography>
													<Typography
														style={{
															fontSize: '20px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														Provider
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														4. Provider is commercial corporate entity.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														5. Provider is not a legal professional nor a legal
														professional partnership; it does not engage in the
														practice of law or partake of any attorney-client
														relations.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														6. Provider is not a notary; it does not engage in
														notarial practice and does not offer or perform
														notarial services.
													</Typography>
													<Typography
														style={{
															fontSize: '20px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														Definitions
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														7. “Notary Member” means a member of the Philippine
														Bar who holds a notarial commission and who
														registers as a notary with NP360°.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														8. “Client Member” means a person who registers with
														NP360° and uses the Service to search for, book, pay
														and set appointments with a Notary-Member.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														9. "Customer Data" means information submitted by a
														Notary-Member to the Service, including his or her
														name, attorney roll number, notarial commission
														details, email and business address, business days
														and hours, mobile number and bank account; it
														likewise includes data generated or resulting from
														the Notary-Member's use of the Service.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														10. "Notarial Fee" is money paid through NP360° by
														Client-Member who receives notarial service as
														compensation to Notary-Member who provides such
														service.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														11. “Platform” means the NP360° website, web or
														mobile application.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														12. “Provider Service Fee” is money paid through
														NP360° by the Client-Member who avails of the
														Service as compensation to Provider for such
														Service.
													</Typography>
													<Typography
														style={{
															fontSize: '20px',
															textAlign: 'left',
															marginBottom: '20px',
														}}
													>
														Terms
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														13. A member of the bar in good standing with a
														valid and current notarial commission may be invited
														to register with NP360°.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														14. By providing Customer Data and affixing the
														signature below, the prospective Notary-Member
														expresses agreement to these Terms of Service.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														15. Notary Member conducts notarial practice
														independently of NP360° and no employment, agency,
														joint venture or partnership arrangement is
														established by virtue hereof.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														16. NP360° facilitates transaction between Notary
														Member and Client Member through the Platform and
														does not, by virtue of such facilitation, partake of
														such transaction.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														17. Notary Member agrees to verification by Provider
														of any Customer Data provided by such Notary Member
														in the course of or following registration with
														NP360°.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														18. The Notary Member agrees to the use by Provider
														of Customer Data to render and improve the Service,
														including informing Client Member about Notary
														Member's name, address, business hours and contact
														details.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														19. Bar membership and possession of notarial
														commission as stated in paragraph 13 are mandatory
														and continuing requirements for membership in
														NP360°.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														20. The Notary Member must promptly inform the
														Provider of non-compliance or failure to maintain
														compliance with membership requirements.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														21. Provider may delist, deregister or terminate the
														username, password or account of a Notary Member who
														fails to maintain compliance with membership
														requirements, commits fraud, misrepresentation or
														acts injurious to Provider, or whose membership or
														registration may give rise to ruinous competition
														among other Notary Members or is otherwise no longer
														in Provider’s best interest.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														22. Notary-Member may voluntarily delist or
														deregister from NP360° at any time and without need
														for cause.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														23. The Notary Member agrees to receive notices
														relative the Service and other products and services
														of Provider and its affiliates.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														24. The Notary Member agrees to use by Provider and
														its affiliates of Customer Data for the improvement
														of NP360° and the development of other products and
														services.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														25. The Notary Member determines:
														<ol type="a">
															<li>
																what notarial services will be provided by such
																Notary Member through the Platform;
															</li>
															<li>
																the Notarial Fee corresponding to each available
																notarial service; and,{' '}
															</li>
															<li>
																business days and hours for providing notarial
																service to Client Members.
															</li>
														</ol>
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														26. The Notary Member may at any time modify any of
														the parameters or specifications in the preceding
														paragraph.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														27. The Notary Member may deny or refuse performance
														of notarial service booked by Client-Member through
														the Service on any ground provided in prevailing
														notarial rules and shall promptly report such denial
														to Provider.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														28. In cases of denial of notarial service by Notary
														Member, Provider shall not remit the Notarial Fee to
														Notary Member and instead refund the Notarial Fee to
														the Client Member.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														29. In cases when Client-Member uploads a document
														image for classification by Provider, the Provider
														shall exercise its best judgment and classify the
														document.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														30. Notary Member shall abide by Provider's
														classification of a Client Member's document based
														on the uploaded image and the corresponding Notarial
														Fee as set by the Notary Member and prevailing at
														the time of booking.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														31. Provider shall periodically account and remit to
														Notary Member all Notarial Fees corresponding to
														notarial services booked and paid by Client Members
														through the Service.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														32. Provider shall inform Notary Member of each new
														booking for notarial service made by a Client Member
														through the Platform and any appointment set or
														requested by such Client Member.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														33. Whenever a Client-Member sets an appointment for
														notarial service through the Service, the Notary
														Member shall ensure his or her presence at the
														business address on the date and time of
														appointment.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														34. A Client Member may, at any time and without
														need of showing cause, cancel a booking made on the
														Platform.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														35. Provider shall promptly notify Notary Member of
														any booking cancellation by Client Member.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														36. Notary Member shall promptly report to Provider
														the completion of notarial service booked on the
														Platform upon which basis Provider shall thereafter
														remit the corresponding Notarial Fee to Notary
														Member.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														37. Notary Member may request from Provider a copy
														of Customer Data or the deletion of Customer Data.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														38. Notary Member shall ensure the accuracy of
														Customer Data, including the list of available
														notarial services, Notarial Fees, business address
														and schedule.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														39. Notary-Member consents to posting on the
														Platform of any review or summary of reviews made by
														Client Members who availed of Notary Member's
														notarial services.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														40. Provider may update these Terms of Service and
														inform Notary Member by email or through the
														Notary-Member's account on the Platform.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														41. Notarial Fee set by Notary Member on the
														Platform represents the entire amount due from the
														Client Member and the Notary Member shall not impose
														any additional fee or charge upon Client-Member.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														42. Notary Member's continued use of the Service
														constitutes agreement to any amended Terms of
														Service communicated by Provider via email or
														through the Notary Member's account on the Platform.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														43. Notary Member agrees to the offsetting of any
														liability to Provider against prospective
														remittances of Notarial Fees.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														44. Notary Member shall be responsible for the
														performance or payment of any tax liabilities,
														obligations or regulatory compliance arising from
														the rendition of notarial service to Client Member.
													</Typography>{' '}
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														45. Notary Member avails of the Service under
														exclusive terms and shall not avail of any similar
														product, service or platform.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														46. Provider exerts reasonable efforts to render the
														Service efficiently and securely but, due to
														limitations or constraints inherent in the nature of
														the Service, cannot be responsible for:
														<ol type="a">
															<li>
																deletion, correction, destruction, damage, loss
																or failure to store Customer Data;
															</li>
															<li>
																any act or omission of third-party vendors or
																service providers such as but not limited to
																web-hosting services and payment gateways;
															</li>
															<li>
																instances of unavailability, delay, error or
																failure in the provision of the Service; and,
															</li>
															<li>
																any indirect, resultant or consequential damage
																arising from any of the foregoing causes.
															</li>
														</ol>
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														47. Provider owns all rights to NP360° and these
														Terms of Service do not constitute a sale or license
														of any product, service or software in favor of
														Notary Member.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														48. Provider may discontinue or modify the Service
														or any feature or functionality thereof which
														changes may result in the termination or amendment
														of these Terms of Service.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														49. Provider may notify Notary Member by means of a
														notice on the Service or via electronic mail to the
														e-mail address on record; Notary Member may notify
														Provider via electronic mail, receipt of which must
														be confirmed by Provider, or by letter addressed to
														2nd Floor, 203 Vicencio Cuyugan St., Brgy. Del
														Pilar, City of San Fernando, Pampanga.
													</Typography>
													<Typography
														style={{
															fontSize: '16px',
															textAlign: 'left',
															marginBottom: '30px',
														}}
													>
														50. These Terms of Service shall be governed by the
														laws of the Republic of the Philippines and all
														cases, disputes, actions or causes of action shall
														be subject to the exclusive jurisdiction of the
														courts in the City of San Fernando, Pampanga.
													</Typography>
												</div>
											</ThemeProvider>
										</div>
										<div className={classes.container}>
											<DialogActions className={classes.modalFooter}>
												<ThemeProvider theme={defaultBtnMaterialTheme}>
													<Button
														style={{
															width: '100%',
														}}
														variant="contained"
														color="primary"
														size="md"
														// onClick={open ? handleClose : null}
														// href={open ? null : `/app/dashboard`}
														onClick={(e) => {
															e.preventDefault();
															this.setState({ termsOfService: false });
														}}
													>
														BACK
													</Button>
												</ThemeProvider>
											</DialogActions>
										</div>
									</div>
								</div>
							</ThemeProvider>
						</DialogContent>
					</Dialog>
				</>
			);
		}
	}

	return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
